import styled from 'styled-components';
import usePlaceWordOnCircle from '../hooks/usePlaceWordOnCircle';

const Word = styled.div`
        position: absolute;
        top:50%;
        left:50%;
        /* color:${props => props.isSelected ? "red" : "black"}; */
        translate: -50% -50%;
        width:100%;
        height:100%;
        /* background-color: red; */
        transform-origin: center center;
        pointer-events: none;
        p {
            text-decoration: ${props => props.fat ? "underline" : "none"};
            font-family: "LD";
            position: absolute;
            margin:0;
            padding:10px 0;
            transform-origin: center center;
            /* padding:1rem; */
            cursor:${props => props.selectable ? "pointer" : "default"};
            pointer-events: all;
            user-select: none;
            color: ${props => props.isSelected ? "var(--pink)" : props.selectable ? "var(--pink)" : "grey"};
            opacity: ${props => props.isSelected ? "1" : props.selectable ? "1" : "0.2"};
        }
`;

function WordOnRing({h,str,globalRotate, radius, startRad,id,index,handleClick,selectedIndex,inc,selectable,fat}) {

    const letterComps = usePlaceWordOnCircle(radius,startRad,str,inc,handleClick,id,index,selectable)
    // console.log(radius);
    return (            
    <Word fat={fat} selectable={selectable} style={{transform:`rotate(${globalRotate}deg)`}} isSelected={selectedIndex === index} data-selected={selectedIndex}>
        {letterComps}
    </Word>
    );
}

export default WordOnRing;