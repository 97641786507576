import { useEffect, useState } from "react";

function useGetProjectIndex(content,setcurrentProjectIndex,currId) {


    useEffect(() => {
        if(!content) return

        content.map((title,index) => {
            const {id} = title
            
            if(id === parseInt(currId)) setcurrentProjectIndex(index)

            
        })
    }, [currId,content]);
    // return ( allProjectsArr );
}

export default useGetProjectIndex;