import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({title, description, name, type,image,url}) {
console.log("🚀 ~ SEO ~ image:", image)
return (
    <Helmet
        defaultTitle="chambresdamies"
        titleTemplate='%s | chambresdamies'
    >
    { /* Standard metadata tags */ }
        <title>{title}</title>
        <meta name='description' content={description} />
        { /* End standard metadata tags */ }
        { /* Facebook tags */ }
        {/* <meta property="og:type" content={type} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`https://api.chambresdamies.com/uploads${image.url}`} />
        <meta property="og:image:width" content={image.width} />
        <meta property="og:image:height" content={image.height} />

        <meta name="twitter:creator" content={name} />
        <meta name="twitter:card" content={"summary_large_image"} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:image" content={`https://api.chambresdamies.com/uploads${image.url}`} />
        <meta name="twitter:description" content={description} /> */}
        { /* End Twitter tags */ }
    </Helmet>
)
}