import styled from 'styled-components';
import Arrow from './Arrow';

const Container = styled.div`
    position:absolute;
    bottom:2rem;
    left:0;
    width:100dvw;
    display: flex;
    gap:3rem;
    justify-content: center;
    align-items: center;
    p {
        font-size:1.5rem;
        /* font-weight:bold; */
        margin:0;
        color:#ef0075;
        min-width:20rem;
        text-align: center;
    }

`;



function Navigation({name,handleGoto}) {
    return (
        <Container>
             <Arrow cb={handleGoto} direction={-1}/>
                <p id="title">{name}</p>
             <Arrow cb={handleGoto} direction={1}/>
        </Container>
    );
}

export default Navigation;