import styled from 'styled-components';

const VideoStyled = styled.video`
height:100dvh ;
  position: absolute;
  left:50%;
  translate: -50%;
  @media (max-width: 767.98px) {
    height:80dvh;
    top:50%;
    translate: -50% -50%;
  }
`;

function Video({v,cb,onTop, show}) {
    console.log("🚀 ~ Video ~ v:", v)
    const handleClick = (e) => {
        e.preventDefault()
        cb(1)
    }
    return (
        <VideoStyled autoPlay loop onClick={handleClick} muted={v.muted}>
            {/* <source src={`https://api.chambresdamies.com${v.url}`} type="video/mov" /> */}
            <source src={`https://api.chambresdamies.com${v.file.url}`} type={v.file.mime} />
        </VideoStyled>
    );
}

export default Video;