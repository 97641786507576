import { styled } from "styled-components";
import { ReactComponent as LogoSVG} from "../assets/fullLogo.svg"

const Container = styled.div`
    /* width:${props => props.w ? `${props.w}px` : "opx"}; */
    width:0;
    opacity:1;
    position:absolute;
    top:50%;
    left:50%;
    translate:-50% -50%;
    display:flex;
    flex-direction:column;
    cursor:pointer;
    transition:width 0.25s ease-in;
    /* pointer-events:none; */
    
    p {
        margin:0;
        font-size:3.5rem;
        font-family: "LDreg";
        color: var(--pink);
    }
    p:last-of-type {
        align-self:flex-end;
        /* color:red; */
    }
    transform:scale(${props => props.active ? 1 : 0}) translate(2px);

    @media (max-width: 430px) {
        /* max-width:40vw; */
        svg {
            transform: scale(1.5);
        }
    }
`

function Logo({activeSelection,lastRadius,height,cb, refState}) {

    const radius = isFinite(lastRadius) && lastRadius > 0 ? lastRadius : 0;
    console.log("🚀 ~ Logo ~ radius:", radius, height)
    return (            
        <Container 
            ref={refState}
            onClick={cb}
            active={activeSelection === null} 
            style={{width:`${(radius/100)*(height) - 100}px`}}
            >
                <LogoSVG />
        </Container>
    );
}

export default Logo;