import { useState,useEffect } from "react";

function usePlaceWordOnCircle(radius,startRad,str,incriment,handleClick,id,index, selectable) {
    
    const [letterComps, setLetterComps] = useState();

    useEffect(() => {
        const letters = Array.from(str).reverse()

        setLetterComps(letters.map((letter,i) => {
            return (
                <p 
                    key={`${letter}_${i}_${id}`}
                    // style={{translate:"-0.5ex -1ch",transformOrigin:"center center",transform:`translate(400px, 400px)`}}
                    style={{translate:"-0.5ex calc(-1ch - 10px)",transform:`translate(${radius * Math.sin(startRad + incriment*i) + radius}px, ${radius * Math.cos(startRad + incriment*i)+ radius}px) rotate(${Math.PI - (startRad + incriment*i)}rad)`}}
                    // style={{transform:`translate(0px, ${radius * Math.cos(startRad + incriment*i)+ radius}px) rotate(${Math.PI - (startRad + incriment*i)}rad) `}}
                    onClick={selectable ? (e) => handleClick(e,id,index) : null}    
                >
                        {selectable ? letter : "."}
                </p>
            )
        }))
        // console.log("RENDER");
    }, [str,selectable,radius]);

    
    return (letterComps);
}

export default usePlaceWordOnCircle;