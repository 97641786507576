import styled from "styled-components";

const Img = styled.img`
  position: absolute;
  display: ${props => props.show ? "block": "none"};
  /* opacity: ${props => props.show ? "1": "0"}; */
  height: 100vh;
  top:0;
  left:50%;
  translate:-50%;
  user-select: none;
  cursor:pointer;
  &:hover {
    /* z-index:1000; */
  }
  &.fullScreen {
    width:100dvw;
    height:100dvh;
    object-fit: cover;
  }
  &.small {
    height: 50dvh;
    top:50%;
    transform: translate(0,-50%);
  }
  &.medium {
    height: 75vh;
    transform: translate(0,20%);
  }
  @media (max-width: 575.98px) {
    &.small {
      width:60dvw;
      height: auto;
    }
  }
  @media (max-width: 767.98px) {
    &.medium {
      /* max-width:100dvw; */
      width:80dvw;
      height: auto;
      top:50%;
      transform: translate(0%, -50%);
    }
    &.fullScreen {
      top:50%;
      transform: translate(0%, -50%);
      width:100dvw;
      height:auto;
      object-fit: cover;
      /* transform: translate(0, 75%); */
    }
  }
`;

function Picture({data, show,cb,onTop,index}) {
    // const url = data.file.size > 1000 ? data.file.formats.medium.url : data.file.url
    return (
        <Img
            className={`${data.size}`}
            show={show ? 1 : 0}

            src={`https://api.chambresdamies.com${data.file.size > 1000 ? data.file.formats.large.url : data.file.url}`}
            onClick={() => cb(1)}    
        
        />
    );
}

export default Picture