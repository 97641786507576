import { useState, useEffect, useRef } from 'react';


const useCount = (speed,direction) => {
  const [count, setCount] = useState(Math.PI*2)
  const [startFromState, setstartFromState] = useState(0);
  
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef();
  const previousTimeRef = useRef();
  
  const animate = time => {
    // console.log("ANIMATING");
    if (previousTimeRef.current !== undefined) {
      // Pass on a function to the setter of the state
      // to make sure we always have the latest state
      setCount(prevCount => {
        
        if(Math.abs(startFromState - prevCount) > Math.PI*4) return startFromState 
        return Math.abs(prevCount) > Math.PI*4 ? Math.PI*2 + speed*direction :(prevCount + (speed*direction))
      });
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }
  
  useEffect(() => {
    if(direction) {
        requestRef.current = requestAnimationFrame(animate);

    }
    return () => cancelAnimationFrame(requestRef.current);
  }, [direction]); // Make sure the effect runs only once

  // useEffect(() => {
  //   console.log(count);
  // }, [count]);

  const stop = (startFrom) => {
    setstartFromState(startFrom)
    // console.log("IS STOPPING");
    setCount(startFrom)
    cancelAnimationFrame(requestRef.current);
  }

  const start = () => {
    console.log("is STARTING");
    requestRef.current = requestAnimationFrame(animate);
  }

  return [count,stop,start]
}

export default useCount