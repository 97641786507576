import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Home from "./components/Home";
import Project from "./Project";
import getAll from "./assets/getFunction"
import { useState,useEffect } from "react";

function App() {
  const [data, setdata] = useState();

  useEffect(() => {
    getAll().then(res => setdata(res))
    
  }, []);
  

  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home data={data} />} />
        <Route path="/:id/:slug" exact element={<Project />} />

        {/* <Route path="/newring" exact element={<NewRing />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
