import { useEffect, useState } from "react";
import axios from 'axios';
import qs from 'qs';

function useGetAllContent(setcurrentProjectIndex,id) {
    const [content, setcontent] = useState();
    const query = qs.stringify({
        populate: [
            'image',
            'image.file',
            'video',
            'video.video'
          ]
        }, {
          encodeValuesOnly: true,
    });
       
    useEffect(() => {
      axios.get(`titles?${query}`).then(res => {
        const projects = res.data.data
        const keysArr = ["image","name","description","video","slug"]
        
        const projectsArr = projects.map((p,i) => {
          
          const {attributes: project} = p
          
          const contentObj = {id:p.id}
          if(p.id === parseInt(id)) setcurrentProjectIndex(i)
          keysArr.forEach(key => {
              if(project[key]) {
                
                if(key === "image") {
                  contentObj.images = project[key].map(img => {
                      const obj = {
                        type:"img",
                        description:img.description,
                        file:img.file.data?.attributes,
                        size:img.size,
                        title:img.title
                      }
                    return img.file.data ? obj : null
                  }).filter(img => img)
                } else if(key === "video") {
                  contentObj.videos = project[key].map(vid => {
                    const obj = {
                      type:"vid",
                      description:vid.description,
                      file:vid.video.data?.attributes,
                      index:vid.index,
                      muted:vid.startMuted
                    }
                  return vid.video.data ? obj : null
                  })
                } else {
                  contentObj[key] = project[key]
                }
              }
            })
            return contentObj
        })
        
        setcontent(projectsArr)
  
  
      })
      
    }, []);

    return {content};
}

export default useGetAllContent;