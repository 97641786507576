
import { useNavigate, useParams } from 'react-router-dom';
import useGetContent from './hooks/useGetContent';
import Root from './styled/Root';
import Picture from './components/Image';
import { useEffect, useState } from 'react';
import Arrow from './components/Arrow';
import DotText from './components/DotText';
import useGetProjectIndex from './hooks/useGetProjectIndex';
import useGetAllContent from './hooks/useGetAllContent';
import Navigation from './components/Navigation';
import Loading from './components/Loading';
import Video from './components/Video';
import useGetTags from './hooks/useGetTags';
import SEO from './components/SEO';

function Project() {
    const {id,slug} = useParams()
    const [currentProjectIndex, setcurrentProjectIndex] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [customTags, setcustomTags] = useState();
    
    const {content} = useGetAllContent(setcurrentProjectIndex,id)
    const {images,name,description, media} = useGetContent(content,currentProjectIndex,id)

    useGetProjectIndex(content,setcurrentProjectIndex,id)

    const [contentIndex, setcontentIndex] = useState(0);
    
    // const [hasLoaded, sethasLoaded] = useState(false);

    useEffect(() => {
        if(media) {
            window.addEventListener('keydown',handleKeyDown)
        }

        return () => {
            window.removeEventListener('keydown',handleKeyDown)
        }
    }, [media]);

    const handleKeyDown = (e) => {
        if(e.code === "ArrowRight" || e.code === "Space") {
            handleNext(1,media.length)
        }
        if(e.code === "ArrowLeft") {
            handleNext(-1,media.length)
        }
        if(e.code === "ArrowUp") {
            handleGoHome()
        }
        
    }

    useEffect(() => {
        setcontentIndex(0)
    }, [id]);
    useEffect(() => {
        const obj = {title:name,url:`https://www.chambresdamies.com/${slug}`}
        // if(description) {
        //     obj.description = description
        // }
        setcustomTags(obj)
    }, [name]);

    const handleNext = (direction,contentLength = media.length) => {
        // const contentLength = media.length// + VIDEOS
        setcontentIndex(prev => {
            if(prev + direction > contentLength - 1) {
                // setcontentIndex(0)
                return 0
            }
            if(prev + direction < 0) {
                // setcontentIndex(contentLength - 1)
                return contentLength - 1
            }
            return prev + direction

        })
        // console.log("nexct");
    }

    const [text, settext] = useState();

    useEffect(() => {
        // console.log(images);
        settext(null)
        if(media && media.length > 0 && contentIndex < media.length) {
            
            if(media[contentIndex].description) {
                settext(media[contentIndex].description)
                // console.log(images[contentIndex]);
            } else {
                settext(description)
            }

        }
    }, [contentIndex,media]);

    const cacheImages = async (srcArray) => {
        const promises = srcArray.reverse().map((src) => {
            return new Promise((resolve, reject) => {
            const url = src.file.size > 1000 ? src.file.formats.large.url : src.file.url
            const img = new Image();
            img.src = `https://api.chambresdamies.com${url}`
            img.onload = () => {resolve();console.log("isLoaded",src);};
            img.onerror = () => reject();
            
            
          });
          
        });
        await Promise.all(promises);
        setIsLoading(false);
      };

      useEffect(() => {
        if(images) {
            cacheImages(images);

        }
      }, [images]);

      useEffect(() => {
        setIsLoading(true)
      },[id])


    const navigate = useNavigate()
    const handleGoHome = (goto) => {
        navigate("/")
    }

    const handleGoto = (direction) => {
        let newIndex = currentProjectIndex + direction 
        const maxIndex = content.length - 1
        if(newIndex > maxIndex) newIndex = 0
        if(newIndex < 0) newIndex = maxIndex
        const {id,slug} = content[newIndex]

        const goto = `/${id}/${slug}`
        navigate(goto)
    }
    
    const handleClick = (e) => {
        const {tagName} = e.target

        if(tagName === "DIV") {
            if(e.screenX < (window.innerWidth / 2)) {
                handleNext(-1)
            }
            if(e.screenX > (window.innerWidth / 2)) {
                handleNext(1)
            }
            console.log("click @", e.screenX);
            console.log(window.innerWidth / 2);
        }
        
    }
    // const tags = useGetTags(customTags)
    return (
        <Root>
            <SEO 
                title={name}
            />
            {/* {tags} */}
            {isLoading && <Loading />}
            {/* <div id="startCover" onClick={()=> handleNext(-1)}></div> */}
            <div id="content" onClick={handleClick} style={{visibility: `${isLoading ? "hidden" : "visible"}`}}>

                {media?.map((data,i) => {
                    if(data.type === "img") {
                        return (
                            <Picture 
                                key={data.id}
                                index={i}
                                data={data} 
                                show={contentIndex >= i} 
                                cb={handleNext}
                                onTop={contentIndex === i}
                            />
                        )                    }
                    if(data.type === "vid") {
                        if(contentIndex === i) {
                            return (
                                <Video
                                    show={contentIndex >= i}
                                    key={data.id} 
                                    v={data}
                                    cb={handleNext}
                                    onTop={contentIndex === i}
                                />
                            )
                        }

                    }
                })}
                {/* <div onClick={()=>handleNext(1)} style={{width:"100%",height:"100%"}}></div> */}
                <Arrow id="home" cb={handleGoHome} direction={0} pos={"center"}/>
            </div>
            {!isLoading && text && <DotText text={text}/>}
            <Navigation name={name} handleGoto={handleGoto}/>
        </Root>
    );
}

export default Project;