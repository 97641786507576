import styled from 'styled-components';
import WordOnRing from './WordOnRing';
import { useEffect, useState } from 'react';
// import useRotateDeg from '../hooks/useRotateDeg';
import useCount from '../hooks/useCount';
import axios from 'axios';
// import test from '../assets/_800x800.png'

const Ring = styled.div`
    position:absolute;
    margin:auto auto;
    left:50%;
    top:50%;
    translate:-50% -50%;
    /* height:80vh; */
    aspect-ratio: 1;
    pointer-events: none;
    /* background-image: url(${props => props.test}); */
    p {
        font-size: ${props => `${props.size}rem`};
        font-style: italic;
    }
    /* transition:transform 0.3s ease-in-out; */
    &.selected {
        transition: transform 0.8s cubic-bezier(0.42, -0.39, 0.35, 1.3);
    }
    
    img {
        opacity:0.2;
        width:100%;
        height:100%;
    }

`;


// function CssRing({ring,radius,titles,direction,size,speed}) {
function CssRing({ringId,ring,data,cb,selection,active,h,selectable,setAllradius}) {

    const [globalRotate, setglobalRotate] = useState(6.28);
    const [selectedIndex, setselectedIndex] = useState();
    const [ringAttributes, setringAttributes] = useState();
    const [gap, setgap] = useState();
    const [titlesArr, settitlesArr] = useState();
    const [wordOnRings, setwordOnRings] = useState();
    const [isStopped,setIsStopped] = useState(false)

    let newStart = 0
    const [count,stop,start] = useCount(ringAttributes?.speed*0.1,ringAttributes?.direction)


    const handleClick = (e,id,i) => {
        
        const wordDiv = e.target.parentElement
        const inc = ringAttributes.kerning
        if(parseInt(wordDiv.dataset.selected) === i) {
            setselectedIndex()
            cb(null,id,ringId)
            start()
            setIsStopped(false)
        } else {
            cb(data[id],id,ringId)
            setselectedIndex(i)
            
            const middleOfwordInRadians = (titlesArr[i].length / 2) * inc
            let lettersUpToWord = 0
            for (let index = 0; index < i; index++) {
                // const element = array[index];
                lettersUpToWord = lettersUpToWord + titlesArr[index].length
                // console.log(titles[index]);
            }
            let newGlobalRotate = ((lettersUpToWord*inc) + (gap*i) + middleOfwordInRadians) + Math.PI
            if(Math.abs(count) > 10) {
                newGlobalRotate = newGlobalRotate + 2*Math.PI
             }
            setglobalRotate(newGlobalRotate)
            stop(newGlobalRotate)
            setIsStopped(true)

        }
    }


    useEffect(() => {
        axios.get(`rings?populate=${ring}`).then(res => {
            const currRingAttr = {...res.data.data.attributes[ring]}  
            // console.log(currRingAttr);
            setringAttributes(currRingAttr)
            setAllradius(prev => [...prev,currRingAttr.radius])

        })
    }, []);

    useEffect(() => {
        if(data) {
            const arr = []
            // console.log(data);
            for(const id in data) {
                arr.push(data[id].name)
            }
            settitlesArr(arr)

        }
    }, [data]);

    useEffect(() => {
        if(titlesArr && ringAttributes) {
            
            const intial = 0
            const totalStringLengths = titlesArr.reduce((a,b) => a + b.length, intial)
            setgap((6.28 - (totalStringLengths * ringAttributes.kerning)) /(titlesArr.length))

        }
        // start()
    }, [titlesArr,ringAttributes]);


    useEffect(() => {
        setglobalRotate(count)
    }, [count]);

    useEffect(() => {
        if(gap && titlesArr) {
   
            const wordsARr = []
            const ringsAreSelected = Object.keys(selectable).some(key => selectable[key].length > 0)
            let index = 0
            for(const id in data) {
                const title = data[id].name
                const thisStart = newStart
                const isSelected = selectedIndex === index 
                const isSelectable = isSelected || !ringsAreSelected ? true : selectable[ringId].includes(id)
                newStart = (title.length*ringAttributes.kerning) + newStart + gap
                wordsARr.push(
                <WordOnRing 
                        h={h}
                        key={id} 
                        id={id} 
                        index={index}
                        radius={(ringAttributes.radius/100)*(h/2)} 
                        str={title} 
                        globalRotate={0} 
                        startRad={thisStart}
                        selectedIndex={selectedIndex}
                        handleClick={handleClick}
                        inc={ringAttributes.kerning}
                        selectable={isSelectable}
                        fat={false}
                    />
                )
                index++
            }
            setwordOnRings(wordsARr)
            // start()
        }
    }, [gap,titlesArr,selectedIndex,ringAttributes,selectable,h,isStopped,active]);

    return (
        <Ring 
            size={ringAttributes?.size} 
            style={{height:`${(ringAttributes?.radius/100)*h}px`,transform:`rotate(${selectedIndex > -1 ? globalRotate : count}rad)`}}
            className={`${selectedIndex > -1 ? "selected" : "rotating"}`}
            isSelected={selectedIndex > -1}
        >   
            {wordOnRings}
        </Ring>
    );
}

export default CssRing;