import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import slugify from 'react-slugify';
import { useEffect, useState } from 'react';

const Dot = styled.div`
    transition: width 0.15s ease-in-out;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: ${props => props.navigating ? "white" : "var(--pink)"};
    /* background-color:  white; */
    position: absolute;
    top:50%;
    left:50%;
    translate:-50% -50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
        font-size: 1.5rem;
        font-family:"Helvetica neue";
        font-weight:bold;
        font-style:italic;
        color:white;
    }
`;

export {Dot}

function DotLink({data,id,active}) {
    
    const [width, setwidth] = useState(0);
    const [navigating, setnavigating] = useState(false);
    
    const navigate = useNavigate()
    
    useEffect(() => {
        if(active) {
            setwidth(4)
        } else {
            setwidth(0)
        }
    }, [active]);
    console.log(data,id);
    const handleClick = () => {
        if(active) {
            setnavigating(true)
            const slug = slugify(data.name)
            const goto = `/${id}/${slug}`
            setwidth(200)
            navigate(goto)

        }
        // setTimeout(() => {
        // }, 400);
        
    }
    return (
        <Dot active={active} navigating={navigating} onClick={handleClick} style={{width:`${width}rem`}} onMouseEnter={active ? () => setwidth(5) : null} onMouseLeave={active ? () => setwidth(4) : null}>
            <span>start</span>
        </Dot>);
}

export default DotLink;