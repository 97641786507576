import styled from 'styled-components';

const Button = styled.button`
    all:unset;
    position:${props => props.center ? "absolute": "static"}; 
    top: ${props => props.center ? `2rem` : "initial"};
    cursor:pointer;
    
    width: 0; 
    height: 0; 
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent; 
    
    border-top:3rem solid  var(--pink);
    transform-origin: center center;
    
    @media (max-width: 767.98px) {
        top: ${props => props.center ? `${props.fromtop}px` : "initial"};

    }
    @media (max-width: 575.98px) {}
    
`;


function Arrow({cb,direction,id, pos, top}) {
    let fromTop = 30;
    if(top) {
        fromTop = Math.max((window.innerHeight/2) - (top/2),20)
    }
    
    return (
        <Button 
            style={{transform:`rotate(${direction*-90}deg)`,left:`calc(50% + ${direction*25}dvw - 1rem)`}}
            onClick={() => cb(direction)}
            id={id}
            center={pos === "center" ? 1 : 0}
            fromtop={fromTop - 10}
        />

    );
}

export default Arrow;