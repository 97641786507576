import styled from 'styled-components';

const Loader = styled.div`
    position:relative;
    width: 100dvw;
    height: 100dvh;
    svg {
        position: absolute;
        top:50%;
        left:50%;
        translate:-50% -50%;
        path {
            fill:none;
            stroke:var(--pink);
            stroke-width: 3px;
            stroke-linecap: round;

        }
        circle {
            animation: fill_up 2s infinite linear;
            stroke:var(--pink);
        }
        /* &:nth-child(1) {
            animation: rotate_one 2s infinite linear;
        }
        &:nth-child(2) {
            animation: rotate_one 3s reverse infinite linear;
        }
        &:nth-child(3) {
            animation: rotate_one 6s infinite linear;
        }
        &:nth-child(4) {
            animation: rotate_one 1.8s reverse infinite linear;
        } */

    }
    @keyframes rotate_one {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes fill_up {
        0% {
            stroke-dashoffset: 75;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }
`;


function Loading() {

    return (
        <Loader>
            {/* <svg width="200" height="200">
                <path d=" M 100 110 A 10 10 0 1 1 100 90" />
            </svg> */}
            <svg width="200" height="200" viewBox="0 0 200 200">
                <circle 
                    strokeLinecap="round"  
                    cx="0" 
                    cy="100" 
                    r="10" 
                    
                    strokeWidth="3" 
                    fill="none" 
                    strokeDasharray="75" 
                    strokeDashoffset="75" 
                    // strokeMitterlimit="0" 
                    transform="rotate(-90) translate(-100 0)" 
                />
            </svg>
            {/* <svg width="200" height="200">
                <path d=" M 125 100 A 25 25 0 0 1 104 125" />
            </svg> */}
            {/* <svg width="200" height="200">
                <path d=" M 101 140 A 40 40 0 0 1 62 86" />
            </svg>
            <svg width="200" height="200">
                <path d=" M 155 100 A 55 55 0 0 1 56 132" />

            </svg> */}
            {/* <svg width="200" height="200">
                <path d=" M 170 100 A 70 70 0 0 1 112 169" />
            </svg> */}
        </Loader>
    );
}

export default Loading;