import { useEffect, useState } from "react";

function useGetContent(content,currIndex,id) {
  
  const [currContent, setcurrContent] = useState({
    images:null,
    description:null,
    name:null
  });
  
  useEffect(() => {

    // setcurrContent()
  
    if(!content || currIndex === -1) return
      const lengthOfmedia = content[currIndex].videos.length + content[currIndex].images.length
      const mediaArr = new Array(lengthOfmedia)
      
      const unsorted = [...content[currIndex].videos,...content[currIndex].images]
      let skipIndexes = []
      let skipCounter = 0
      unsorted.forEach((el,i) => {
        if(el.type === "vid") {
          if(el.index && el.index < lengthOfmedia+1) {
            mediaArr[el.index] = el
            skipIndexes.push(el.index)
          } else {
            mediaArr[lengthOfmedia - i - 1] = el
          }
        }
        if(el.type === "img") {
          const imgIndex = i -  content[currIndex].videos.length
          while(skipIndexes.includes((imgIndex) + skipCounter)) {
            skipCounter++
          }
          
          mediaArr[imgIndex+skipCounter] = el
        }
      })
      setcurrContent({
        images:content[currIndex].images,
        description:content[currIndex].description,
        name:content[currIndex].name,
        media:mediaArr
      })

  }, [content,currIndex,id]);
 
    

    return currContent;
}

export default useGetContent;