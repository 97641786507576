import axios from "axios";
const dataTypes = ["themes","titles","clients","collaborators"]
const get = {}
get.themesData = ["themes","populate=titles"]
get.titlesData = ["titles","populate[0]=themes&populate[1]=clients&&populate[2]=collaborators"]
get.clientsData = ["clients","populate[0]=titles&populate[1]=colaborators"]
get.colData = ["collaborators","populate[0]=titles"]


export default async () => {
    const data = {}
    for(const key in get) {
        data[key] = await getThese(get[key][0],get[key][1])
    }
    console.log("🚀 ~ data:", data)
    return data
}

const getThese = async (endpoint,query) => {

    return await axios.get(`${endpoint}/?${query}`)
    .then((res)=> {
        const obj = {}
        res.data.data.forEach(el => {
            obj[el.id] = el.attributes

            dataTypes.forEach(type => {

                if(el.attributes[type]) {
                    const obj = {}
                    el.attributes[type].data.forEach(t => obj[t.id] = t.attributes)
                    el.attributes[type] = obj
                }
            })

        })
        return obj
    })

}