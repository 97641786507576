import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Dot } from './DotLink';
import ReactMarkdown from 'react-markdown'
const NewDot = styled(Dot)`
    width:2rem;
`
const Text = styled.div`
    display: ${props => props.active ? "block" : "none"};
    position:absolute;
    /* top:50%;
    left:50%;
    translate: -2rem -50%; */
    top:calc(50% - 1rem - 0.75em);
    left:calc(50% - 1.75rem);
    background-color: white;
    padding:1em 1rem 1em 4rem;
    max-width:${props => props.out ? "42vw" :"25vw"};
    max-height: 45vh;
    overflow: scroll;

    p, a {
        font-size:1.5rem;
        font-family: "Helvetica neue";
        /* font-family: "LDreg"; */
        margin: 0;
        color: var(--pink);
        font-style:italic;
        line-height: 1.1;
        translate:0 1px;

    }
    p:not(:last-child) {
        margin-bottom: 1em;
    }
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: underline;

        }
    }
    @media (max-width: 767.98px) {
        max-width:40vw;
    }
`

function DotText({text}) {
    const [active, setactive] = useState(false);
    const [outOfBounds, setoutOfBounds] = useState(false);
    const ref = useRef()
    const handleClick = () => {
        if(text) {
            setactive(!active)
        }
    }
    useEffect(() => {
        if(active) {
            const bounds = ref.current.getBoundingClientRect()
            if(bounds.height + bounds.top > window.innerHeight - 100) {
                setoutOfBounds(true)
            } 
        } else {
            setoutOfBounds(false)
        }
        
    }, [active,text]);

    return (
        <>
        <Text active={active ? 1 : 0} ref={ref} out={outOfBounds}>
            <ReactMarkdown>{text}</ReactMarkdown>
        </Text>
        <NewDot onClick={handleClick}>
        </NewDot>
        </>
    );
}

export default DotText;