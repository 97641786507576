 import { styled } from "styled-components";
import CssRing from "./CssRing";
import { useState, useEffect, useRef } from "react";
import '../App.css'
import DotLink from "./DotLink";
import Arrow from "./Arrow";
import Logo from "./Logo";
import About from "./About";
import SEO from "./SEO";

const RingContainer = styled.div`

    position: relative;
    width:100dvw;
    height: 100dvh;
    overflow: hidden;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    #homeArrow {
        transition: rotate 0.2s ease-in-out;
        rotate: ${props => props.about ? "180deg" : "0deg"};
    }
`


function Home({data}) {
    // const {themesData,titlesData,clientsData,colData} = data
    // const themesData = useGet("themes","populate=titles")
    // const titlesData = useGet("titles","populate[0]=themes&populate[1]=clients&&populate[2]=collaborators")
    // const clientsData = useGet("clients","populate[0]=titles&populate[1]=colaborators")
    // const colData = useGet("collaborators","populate[0]=titles")

    const [selectable, setSelectable] = useState({
        1:["3"],
        2:[],
        3:[],
        4:[]
    });
    const [activeRings, setactiveRings] = useState([false,false,false,false]);
    const [activeSelection,setActiveSelection] = useState({1:null,2:null,3:null,4:null})
    // const [activeSelection,setActiveSelection] = useState([null,null,null,null])
    // const [selectionIsactive, setselectionIsactive] = useState(false);
    const allDataTypes = ["themes","titles","clients","collaborators"]

    const handleClick = (data,id,ringId) => {
        console.log(data,id,ringId);
        const ringIndex = ringId - 1
        console.log(ringIndex);
        const newActive = [...activeRings]
        newActive[ringId-1] = data ? true : false;
        setactiveRings(newActive)

        if(!data) {
            // console.log("remove",id);
            setActiveSelection((prev) => ({...prev,[ringId]:null}))
        } else {
            setActiveSelection((prev) => ({...prev,[ringId]:id}))
        }

    }

    const searchForRelated = (active) => {
        // console.log(active);
        const allData = [null,data.themesData,data.titlesData,data.clientsData,data.colData]
        // const allDataTypes = [null,"themes","titles","clients","collaborators"]
        const newRelations = {
            1:[],
            2:[],
            3:[],
            4:[]
        }
        let searchTheseTitles = []
        let howManyActiveRings = 0;
        for(const ringID in active) {
            const selectedID = active[ringID]
            if(selectedID) howManyActiveRings++
            if(ringID === "2" && selectedID) {
                searchTheseTitles = [selectedID]
                // console.log("IS TITLE");
                // continue;
            }

            if(ringID !== "2" && selectedID) {
                // newRelations[ringID] = selectedID
                const titlesObj = allData[ringID][selectedID].titles
                // console.log(allData[ringID]);
                searchTheseTitles = [...searchTheseTitles,...Object.keys(titlesObj)]
            }
        }
        const toFindDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
        const duplicateElements = toFindDuplicates(searchTheseTitles);
        // console.log(howManyActiveRings);
        
        let consolidatedTitles = howManyActiveRings === 1 ? [...new Set(searchTheseTitles)] : [...new Set(duplicateElements)]
        if(active[2]) {
            consolidatedTitles = [active[2]]
        }
        // const consolidatedTitles = [...new Set(searchTheseTitles)]
        // console.log(consolidatedTitles);

        consolidatedTitles.forEach(titleId => {
            // console.log(titleId);
            const titlesObj = data.titlesData[titleId]

            allDataTypes.forEach((type,i) => {
                if(type !== "titles") {

                    // console.log(Object.keys(titlesObj[type]));
                    newRelations[i + 1] = active[i + 1] ? [active[i + 1]] : Object.keys(titlesObj[type])
                }  
                
                if(type === "titles" && !active[2]) {
                    newRelations[i + 1] = consolidatedTitles
                }
            })
        })
        setSelectable(newRelations)

    }

    useEffect(() => {
        // console.log(activeSelection);
        if(data) {
            searchForRelated(activeSelection)

        }


    }, [activeSelection,data]);

    const [winHeight, setwinHeight] = useState();

    const calculateMaxRadius = () => {
        if(window.innerHeight > window.innerWidth) {
            setwinHeight(window.innerWidth)
        } else {
            setwinHeight(window.innerHeight)
        }
    }

    useEffect(() => {
        calculateMaxRadius()
        window.addEventListener("resize",calculateMaxRadius)

        return () => {
            window.removeEventListener("rezie",calculateMaxRadius)
        }
    }, []);

    const [lastRadius, setlastRadius] = useState(0);
    const [allRadius, setallRadius] = useState([]);
    
    useEffect(() => {
        setlastRadius(Math.min(...allRadius))
    }, [allRadius]);

    const [showAbout, setshowAbout] = useState(false);

    const logo = useRef()

    // const tags = useGetTags()

    return (
        <RingContainer about={showAbout}>
            <SEO 
                title={null}
            />
            <Arrow cb={showAbout ? () => setshowAbout(false) : ()=> {}} direction={0} pos={"center"} top={winHeight} id="homeArrow"/>
            {data && !showAbout ? 
            // {themesData && titlesData && clientsData && colData && !showAbout ? 
            <>
                <CssRing h={winHeight} selection={activeSelection} selectable={selectable} active={activeRings} ringId={1} ring={"Themes"} data={data.themesData} cb={handleClick} setAllradius={setallRadius}/>
                <CssRing h={winHeight} selection={activeSelection} selectable={selectable} active={activeRings} ringId={2} ring={"Titles"} data={data.titlesData} cb={handleClick} setAllradius={setallRadius}/>
                <CssRing h={winHeight} selection={activeSelection} selectable={selectable} active={activeRings} ringId={3} ring={"Clients"} data={data.clientsData} cb={handleClick} setAllradius={setallRadius}/>
                <CssRing h={winHeight} selection={activeSelection} selectable={selectable} active={activeRings} ringId={4} ring={"Collaborators"} data={data.colData} cb={handleClick} setAllradius={setallRadius}/>
                {activeSelection[2] !== null ? <DotLink active={activeSelection[2] !== null} data={data.titlesData[activeSelection[2]]} id={activeSelection[2]}/> : null}
            </>
            :
            null
        }

            
            <Logo activeSelection={activeSelection[2]} lastRadius={lastRadius} height={winHeight} cb={() => setshowAbout(prev => !prev)} refState={logo}/>
            {/* <div
                style={{position:"absolute",height:"100vh",width:"2px",backgroundColor:"red",left:"calc(50% - 1px)",top:"0"}}
            ></div> */}
            {showAbout ? <About logo={logo} /> : null}
        </RingContainer>
    );
}

export default Home