import styled from 'styled-components';
import axios from "axios";
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown'

const Container  = styled.div`
    position:absolute;
    top: ${props => `${props.top + 50}px`};
    width:100%;
    p,h3 {
        text-align:center;
    }
    h3 {
        font-weight:normal;
        margin:3.5rem 0;
    }

`;

const About = ({logo}) => {

    const [text, settext] = useState();

    useEffect(() => {
        axios.get("info-page").then(res => {
            console.log("🚀 ~ axios.get ~ res:", res)
            settext(res.data.data.attributes.content)
        })
    }, []);
    const refbounds = logo.current.getBoundingClientRect()
    return (
        <Container top={refbounds.bottom}>
            <ReactMarkdown>{text}</ReactMarkdown>
        </Container>
    );
}

export default About;