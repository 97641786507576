import styled from 'styled-components';

const Root = styled.div`

    position: relative;
    width:100dvw;
    height:100dvh;
    overflow: hidden;
    background-color:  white;
    /* background-color:  #fc688e; */
    #content {
        position: relative;
        width:100dvw;
        height:100dvh;
        background-color: white;
        /* pointer-events: none; */
        /* clip-path: circle(2vw at center);
        &.loading {
            clip-path: circle(0vw at center);
        }
        &.active {
            transition: clip-path 1s ease-in-out;
            
            clip-path: circle(100vw at center);
        } */
    }
    #startCover {
        position: absolute;
        width:100%;
        top:0;
        height: 100%;
    }
    #home {
        rotate: 180deg;
    }
   
    /* button {
        position: absolute;
        right:0;
    } */
`;

export default Root;